import { Paper, Text, Title } from '@mantine/core'
import { IconLocation, IconMail, IconPhone, IconBrandInstagram, IconBrandLinkedin, IconBrandX } from '@tabler/icons-react'
import BibotLogo from '../../assets/logo/bibot-high-resolution-logo-black-transparent.png'

export default function Footer() {
    const currentYear = new Date().getFullYear()
  return (
    <Paper bg='#3d5a6c' h={'100%'} style={{borderRadius: 0, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
       <div className='footerContent'>
       <img src={BibotLogo} alt={'BiBot Logo'} width={100} height={100}/>
        <div style={{color: '#fff',  marginBlock: '40px'}}>
        <Title order={5}>Quick Links</Title>
        <Text>Home</Text>
        <Text>Features</Text>
        <Text>Pricing</Text>
        <Text>Developer</Text>
        {/* <Text>About us</Text> */}

        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', color: '#fff', marginBlock: '40px'}}>
        <Title style={{display: 'flex', marginBottom: 10}} order={5}>Contact Info</Title>
        <Text  style={{display: 'flex', marginBottom: 10}}><IconLocation style={{ marginRight: 5}}/> 4125 Gardensen Dr, Dale City, 22193</Text>
        <Text  style={{display: 'flex', marginBottom: 10}}><IconPhone style={{ marginRight: 5}} /> +1 (571) 677-2959</Text>
        <Text  style={{display: 'flex', marginBottom: 10}}><IconMail style={{ marginRight: 5}} />  bibot@thecloudsecllc.com</Text>
        </div>
        <div style={{ color: '#fff'}}>
        <Title order={5} style={{color: '#fff'}}>Connect with us</Title>
        <div style={{ display:'flex', marginTop: 10}}>
  {/* <IconBrandSlack style={{ marginRight: '20px'}} /> <IconBrandGoogle style={{ marginRight: '20px'}}/> */}
  <a href='https://x.com/Bibot_cloudsec'>  <IconBrandX color='#fff' style={{ marginRight: '20px'}}/> </a>
    <IconBrandInstagram style={{ marginRight: '20px'}}/> <IconBrandLinkedin />
  </div>
        </div>
       </div>
      <Text c={'dimmed'} style={{ color: '#fff', marginBottom: '20px', textAlign: 'center', paddingInline: '1em'}}> &copy; {currentYear} BiBot, a CloudSec LLC Product. All rights reserved.</Text>
    </Paper>
  )
}

