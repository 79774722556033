// import { useEffect, useContext, useCallback, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppRoutes } from 'routes/Routes'
import './App.css'
import '@mantine/core/styles.css'
import '@mantine/charts/styles.css'
import '@mantine/dates/styles.css'
import {createTheme, MantineProvider } from '@mantine/core'
import { BiBot } from 'bibot'
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'

const App: React.FC=() => {
    const isProd=process.env.REACT_APP_ENV==='prod'
    const clientId=isProd
        ? 'b4d8e408-3091-700d-3e46-794b7ac2049b'
        :'3f78da71-11c4-4a0b-833a-f079faa269d3'
    const test=!isProd

    const theme = createTheme({
        breakpoints: {
          xs: '30em',
          sm: '48em',
          md: '64em',
          lg: '74em',
          xl: '90em',
        },
      })

    return (
        <MantineProvider theme={theme} stylesTransform={emotionTransform}>
            <MantineEmotionProvider>
                <Router>
                    <AppRoutes />
                </Router>
                <BiBot clientId={clientId} test={test} internalUserOnly={{
                            internalUseOnly: false,
                        }} />
            </MantineEmotionProvider>
        </MantineProvider>
    )
}

export default App
