import React, { useContext} from 'react'
import '../../styles/landingPage.css'
import { Button, Card, Paper, Text, Title } from '@mantine/core'
import BotImage from '../../assets/landingpage/bot.jpg'
import {
  IconBrandInstagram,
  IconBrandLinkedin,
  IconCheck,
  IconBrandX,
  IconCircleArrowDownFilled,
  IconLogin2,
  IconPlugConnected,
  IconBrain,
  IconChartPie,
  IconPalette,
  IconTool,
} from '@tabler/icons-react'
import { AppContext } from 'context'
import Navbar from 'components/pages/Navbar'
import Footer from 'components/pages/footer'
import { ROUTES } from 'config'

export default function LandingPage() {
  const { Appstate } = useContext(AppContext)
  const { lpData } = Appstate

  const renderCard = () => { 
    return lpData?.features.items.map((item) => {
      return (
        <Card key={item.key} withBorder className="pop-up-card featureCard">
          {item.key === 'easy-integration' && <IconPlugConnected size={60} color="#1f4760" />}
          {item.key === 'customizable-bibot' && <IconPalette size={60} color="#1f4760" />}
          {item.key === 'knowledge-base-management' && <IconBrain size={60} color="#1f4760" />}
          {item.key === 'third-party-integrations' && <IconTool size={60} color="#1f4760" />}
          {item.key === 'analytics-dashboard' && <IconChartPie size={60} color="#1f4760" />}
          <Card.Section p={20}>
            <Title mb={20} order={3}>
              {item.title}
            </Title>
            <Text>{item.description}</Text>
          </Card.Section>
        </Card>
      )
    })
  }

  const useCase = [
    {
      title: 'Support',
      list: {
        case1: 'Answer common questions with a virtual agent',
        case2: 'Resolve customer problems',
        case3: 'Create support tickets',
        case4: 'Send links to help center articles or the educational content',
        case5: 'Transfer to live agents (customer support)',
      },
    },
    {
      title: 'Marketing',
      list: {
        case1: 'Initiate conversations proactively with greetings',
        case2: 'Educate about product and services',
        case3: 'Collect leads and pass them to email marketing list',
        case4: 'launch AI bots on landing pages to boost conversion',
      },
    },
    {
      title: 'Sales',
      list: {
        case1: 'Qualify and segment leads',
        case2: 'Create personalized sales offers ',
        case3: 'Pass leads to CRM',
        case4: 'Transfer qualified chats to sales representatives',
        case5: 'Schedule meetings',
      },
    },
  ]

  return (
    <>
      <div className="mainBanner" id="Home">
        <Navbar />
        <div className="bannerContent" style={{ marginTop: 130 }}>
          <div className="bannerSectionA pop-up-card ">
            <Title order={1}
              className="IntroText"
              dangerouslySetInnerHTML={{ __html: lpData?.home.welcome || '' }}
            />
            <Text>{lpData?.home.subText} </Text>
            <div style={{ marginTop: '50px' }}>
              {lpData?.home.freeTrialButtonText && (
                <Button onClick={() => window.open(ROUTES.ADMIN_PORTAL, '_blank')} size="lg" color="#1f4760" style={{ fontWeight: 'normal' }}>
                  <IconLogin2 /> &nbsp;{lpData?.home.freeTrialButtonText}
                </Button>
              )}
            </div>
            <Text c={'dimmed'} mt={40}>
              Get in touch with us on our platform
            </Text>
            <div className="socialMediaIcons">
              <a href="https://x.com/Bibot_cloudsec">
                <IconBrandX color="#000" style={{ marginRight: '20px' }} />
              </a>
              <IconBrandInstagram style={{ marginRight: '20px' }} />
              <IconBrandLinkedin />
            </div>
          </div>
          <div className="bannerSectionB">
            <img src={BotImage} alt={'Bot Logo'} width={'100%'} className="bounce-card pop-up-card" />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="#Features">
            <IconCircleArrowDownFilled color="#1f4760" size={70} />
          </a>
        </div>
      </div>
      <Paper id="Features" bg={'#1f4760'} className="features">
        <div
          style={{
            width: '60%',
            backgroundColor: '#fff',
            justifyContent: 'flex-end',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Title order={3} style={{ fontSize: 36 }}>
            {lpData?.features.title}
          </Title>
        </div>
        <div className="featureCardMainParent">{renderCard()}</div>
      </Paper>
      <Paper bg={'#000'} style={{ borderRadius: 0 }} className="casePaper">
        <Title c={'#fff'} style={{ textAlign: 'center' }} mb={60}>
          Cover All Customer Journey Touch Points
        </Title>
        <div className="case">
          {useCase.map((data, index) => (
            <Card key={index} p={25} className="card" mb={100}>
              <Card.Section p={20}>
                <Title order={3} style={{ textAlign: 'center', fontSize: 30 }}>
                  {data.title}
                </Title>
              </Card.Section>
              <Text style={{ display: 'flex', paddingBlock: 10 }}>
                <IconCheck size={24} color="#1f4760" /> {data.list.case1}
              </Text>
              <Text style={{ display: 'flex', paddingBlock: 10 }}>
                <IconCheck size={24} color="#1f4760" /> {data.list.case2}
              </Text>
              <Text style={{ display: 'flex', paddingBlock: 10 }}>
                <IconCheck size={24} color="#1f4760" /> {data.list.case3}
              </Text>
              <Text style={{ display: 'flex', paddingBlock: 10 }}>
                <IconCheck size={24} color="#1f4760" /> {data.list.case4}
              </Text>
            </Card>
          ))}
        </div>
      </Paper>
      <Footer />
    </>
  )
}
