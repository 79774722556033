import {
    // IconBell,
    // IconCreditCard,
    // IconFileUpload,
    // IconSettings,
    // IconChevronDown,
    // IconPoint,
    // IconMessage,
    // IconLayoutDashboard,
    // IconAdjustments,
    // IconPlugConnected,
  } from '@tabler/icons-react'
  
   export const lpData = {
    home: {
      welcome: 'Welcome To <Strong>BiBot</Strong>: Your Business Intelligent Assistant',
      subText: 'Revolutionize your online experience with BiBot. Your intelligent chatbot solution designed to streamline customer support and enhance user engagement on your website.',
      freeTrialButtonText: 'Start free trial'
    },
    features: {
      title: 'Features',
      items: [
        {
          key: 'easy-integration',
          title: 'Easy Integration',
          description: 'Quickly integrate BiBot into your website with just a few lines of code.',
          icon: 'icon-path-1'
        },
        {
          key: 'customizable-bibot',
          title: 'Customizable BiBot',
          description: 'Personalize the BiBot\'s appearance and behavior to match your brand.',
          icon: 'icon-path-1'
        },
        {
          key: 'knowledge-base-management',
          title: 'Advanced Knowledge Base Management',
          description: 'Upload, update, and manage documents to train BiBot for accurate responses.',
          icon: 'icon-path-1',
        },
        {
          key: 'third-party-integrations',
          title: 'Third-Party Integrations',
          description: 'Connect with Slack, WhatsApp, Microsoft Teams, and Discord for seamless human intervention.',
          icon: 'icon-path-1',
        },
        {
          key: 'analytics-dashboard',
          title: 'Analytics Dashboard',
          description: 'Monitor chatbot performance and user interactions to gain valuable insights.',
          icon: 'icon-path-1',
        }
      ]
    },
    useCase: {
      cases:[
        {title: 'Support', list: {
          case1: 'Answer common questions with a virtual agent',
          case2: 'Resolve customer problems',
          case3: 'Create support tickets',
          case4: 'Send links to help center articles or the educational content',
          case5: 'Transfer to live agents (customer support)',
        }
    
      },
        {title: 'Marketing', list: {
          case1: 'Initiate conversations proactively with greetings',
          case2: 'Educate about product and services',
          case3: 'Collect leads and pass them to email marketing list',
          case4: 'launch AI bots on landing pages to boost conversion',
        }},
        {title: 'Sales', list: {
          case1: 'Quality and segment leads',
          case2: 'Create personalized sales offers ',
          case3: 'Pass leads to CRM',
          case4: 'Transfer qualified chats to sales representatives',
          case5: 'Schedule meetings',
        }}
      ]
    },
      'pricing': {
    'title': 'Pricing Plans',
    'plans': [
      {
        'key': 'trial',
        'name': 'Trial',
        'price': '$0/month (14 days trial)',
        'features': [
          'Data security measures',
          'Basic Chat Escalation via 3rd party integrations',
          'Basic reporting',
          'Basic webscraping',
          'Chat history available for 7 days',
          'Continuous training for knowledge base updates',
          'Up to 300 words training',
          'Up to 100 chats per month',
          'Up to 3 suggested FAQs',
          'Up to 5 Predefined Questions',
          'FAQs with intelligent follow-up questions'
        ]
      },
      {
        'key': 'basic',
        'name': 'Basic',
        'price': '$49.99/month or $41.67/month billed annually',
        'features': [
          'Data security measures',
          'Advanced webscraping',
          'Chat history available for 14 days',
          'Continuous training for knowledge base updates',
          'Up to 3000 words training',
          'Up to 1000 chats per month',
          'Limited Reporting'
        ]
      },
      {
        'key': 'advanced',
        'name': 'Advanced',
        'popular': true,
        'price': '$149.99/month or $125.00/month billed annually',
        'features': [
          'Data security measures',
          'Basic Chat Escalation via 3rd party integrations',
          'Advanced webscraping',
          'Chat history available for 30 days',
          'Continuous training for knowledge base updates',
          'Up to 30000 words training',
          'Up to 10000 chats per month',
          'Up to 10 FAQs',
          'Up to 5 suggested FAQs',
          'Full reporting',
          'Team collaboration for up to 5 members'
        ]
      },
      {
        'key': 'premium',
        'name': 'Premium',
        'price': '$499.99/month or $416.67/month billed annually',
        'features': [
          'Data security measures',
          'Advanced Chat Escalation with custom options',
          'Advanced webscraping',
          'Chat history available for 90 days',
          'Continuous training for knowledge base updates',
          'Up to 300000 words training',
          'Up to 25000 chats per month',
          'Up to 10 suggested FAQs',
          'Up to 25 Predefined Questions',
          'Full reporting',
          'Team collaboration for up to 10 members',
          'Dedicated accounts manager',
          'Live customer support',
          'White-glove onboarding and setup'
        ]
      }
    ]
    ,
    'cancelationPolicy': 'Cancel anytime via email to bibot@thecloudsecllc.com, subscription cancels at the end of the billing period. Cancellation results in credit, not refund.'
  },
    developers: {
      title: 'Developers',
      intro: 'Get started with integrating BiBot into your website. Follow our comprehensive guide to ensure a smooth setup.',
      steps: [
        {
          key: 'register',
          title: 'Register with BiBot',
          description: 'Visit BiBot\'s registration page and sign up by providing your organization\'s details. Complete the registration process by confirming your email address.'
        },
        {
          key: 'install-package',
          title: 'Install the BiBot Package',
          description: 'Open your terminal and install the BiBot package using npm: \n```npm install bibot```'
        },
        {
          key: 'configure-bibot',
          title: 'Configure the BiBot',
          description: 'Log into the BiBot portal, retrieve your client ID, and insert the BiBot component into your main application file as follows: \n```import { BiBot } from \'bibot\';```'
        },
        {
          key: 'customize-deploy',
          title: 'Customize and Deploy',
          description: 'Access the BiBot portal to customize the chatbot\'s behavior and appearance. Save your configuration and deploy the changes to your website.'
        }
      ],
      support: 'For additional help, visit our support page or reach out via our dedicated helpdesk or email support.'
    },
    about: {
      title: 'About BiBot',
      description: 'BiBot is designed to streamline customer support and enhance user engagement. Our intelligent chatbot solution offers easy integration, advanced customization, and comprehensive support to ensure your business thrives online. Learn more about how BiBot can revolutionize your customer interaction.'
    }
  }