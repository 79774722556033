export const ROUTES = {
	HOME: '/',
	ADMIN_DASHBOARD: '/admin-portal',
	DEVELOPER: '/developer',
	NOTFOUND: '*',
	ERROR: '/error',
	PRICING: '/pricing',
	ADMIN_PORTAL: process.env.REACT_APP_ENV==='dev' ? 'https://admin.dev.bibot.app' : 'https://admin.bibot.app',
	DEVELOPER_APIS_PAGE: 'https://documenter.getpostman.com/view/14084259/2sA3XSBgpt'
}

export const planNames = ['Freemium','Basic Tier','Advanced Tier','Premium Tier']