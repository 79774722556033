import { Table, Title, Card, Container } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { primary_color } from 'styles/colors'

const subscriptionData = [
  { key: 'chats_per_month', feature: 'Chats/Month', trial: 100, basic: 1000, advanced: 10000, premium: 25000 },
  { key: 'all_integrations', feature: 'All Integrations (No Code and Code)', trial: <IconCheck />, basic: <IconCheck />, advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'chat_escalation', feature: 'Chat Escalation - 3rd Party Integrations (Slack, Teams, WhatsApp etc.)', trial: 'Basic', basic: '-', advanced: 'Basic', premium: 'Advanced (Hours of operation etc)' },
  { key: 'chat_history', feature: 'Chat History', trial: '7 days', basic: '14 days', advanced: '30 days', premium: '90 days' },
  { key: 'data_security', feature: 'Data Security', trial: <IconCheck />, basic: <IconCheck />, advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'dedicated_accounts_manager', feature: 'Dedicated Accounts Manager', trial: '-', basic: '-', advanced: '-', premium: <IconCheck />, },
  { key: 'faq_max', feature: 'FAQ Max', trial: 5, basic: '-', advanced: 10, premium: 25 },
  { key: 'faqs_intelligent_follow_up', feature: 'FAQs Intelligent Follow Up Questions', trial: <IconCheck />, basic: '-', advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'knowledge_base_updates', feature: 'Knowledge Base Updates (Continuous Training)', trial: <IconCheck />, basic: <IconCheck />, advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'live_customer_support', feature: 'Live Customer Support', trial: '-', basic: '-', advanced: '-', premium: <IconCheck />, },
  { key: 'pages_word_counts', feature: 'Word Counts', trial: '300 word count', basic: '3000 word count', advanced: '30000 word count', premium: '300000 word count' },
  { key: 'predefined_questions', feature: 'Predefined Questions (FAQs)', trial: <IconCheck />, basic: '-', advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'reporting', feature: 'Reporting', trial: <IconCheck />, basic: 'Limited', advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'suggested_faqs_max', feature: 'Suggested FAQs Max', trial: 3, basic: '-', advanced: 'Up to 5', premium: 'Up to 10' },
  { key: 'team_collaboration', feature: 'Team Collaboration', trial: '-', basic: '-', advanced: 5, premium: 10 },
  { key: 'webscraping', feature: 'Webscraping', trial: <IconCheck />, basic: <IconCheck />, advanced: <IconCheck />, premium: <IconCheck />, },
  { key: 'white_glove_onboarding', feature: 'White-glove onboarding and setup', trial: '-', basic: '-', advanced: '-', premium: <IconCheck />, },
  { key: 'pricing_monthly', feature: 'Pricing (Billed Monthly)', trial: '-', basic: '$49.99', advanced: '$149.99', premium: '$499.99' },
  { key: 'pricing_annually', feature: 'Pricing (Billed Annually)', trial: '-', basic: '$499.99', advanced: '$1,499.99', premium: '$4,999.99' },
  { key: 'monthly_rate_annually', feature: 'Monthly Rate (If Billed Annually $)', trial: '-', basic: '$41.67', advanced: '$125.00', premium: '$416.67' },
  { key: 'savings_annually_dollar', feature: 'Savings (If Billed Annually $)', trial: '-', basic: '$99.89', advanced: '$299.89', premium: '$999.89' },
  { key: 'savings_annually_percent', feature: 'Savings (If Billed Annually %)', trial: '-', basic: '16.65%', advanced: '16.66%', premium: '16.67%' },
]

export default function SubscriptionComparisonTable() {
  return (
    <Container size="xl">
      <Card shadow="md" padding="lg" style={{ marginBottom: '2rem', backgroundColor: '#ffffff', borderRadius: '8px' }}>
        <Title style={{ marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 'bold' }}>Subscription Plans Comparison</Title>
        <Table
          verticalSpacing="sm"
          horizontalSpacing="sm"
          highlightOnHover
          style={{
            fontSize: '0.875rem',
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <thead>
            <tr style={{ backgroundColor: primary_color }}>
              <th style={{ textAlign: 'left', padding: '0.5rem', fontWeight: 'bold', color: 'white' }}>Feature</th>
              <th style={{ textAlign: 'center', padding: '0.5rem', fontWeight: 'bold', color: 'white' }}>Trial</th>
              <th style={{ textAlign: 'center', padding: '0.5rem', fontWeight: 'bold', color: 'white' }}>Basic</th>
              <th style={{ textAlign: 'center', padding: '0.5rem', fontWeight: 'bold', color: 'white' }}>Advanced</th>
              <th style={{ textAlign: 'center', padding: '0.5rem', fontWeight: 'bold', color: 'white' }}>Premium</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionData.map((item, index) => (
              <tr key={item.key} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f8f9fa' }}>
                <td style={{ textAlign: 'left', padding: '0.5rem', border: '1px solid #e9ecef' }}>{item.feature}</td>
                <td style={{ textAlign: 'center', padding: '0.5rem', border: '1px solid #e9ecef' }}>{item.trial}</td>
                <td style={{ textAlign: 'center', padding: '0.5rem', border: '1px solid #e9ecef' }}>{item.basic}</td>
                <td style={{ textAlign: 'center', padding: '0.5rem', border: '1px solid #e9ecef' }}>{item.advanced}</td>
                <td style={{ textAlign: 'center', padding: '0.5rem', border: '1px solid #e9ecef' }}>{item.premium}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
  )
}
