import { useEffect, useState } from 'react'
import BibotLogo from '../../assets/logo/bibot-high-resolution-logo-black-transparent.png'
import { Button } from '@mantine/core'
import { IconX, IconMenu2, IconArrowUpRight } from '@tabler/icons-react'
import { useLocation } from 'react-router-dom'
import { ROUTES } from 'config'

export default function Navbar() {
  const location = useLocation()

  interface Tab {
    title: string;
    path: string;
  }

  const tabs = [
    { title: 'Home', path: ROUTES.HOME },
    { title: 'Features', path: 'Features' },
    { title: 'Pricing', path: ROUTES.PRICING },
    {
      title: 'Developer',
      path: 'https://documenter.getpostman.com/view/14084259/2sA3XSBgpt',
    },
    // {title: 'About us'},
  ]
  const [currentTab, setCurrentTab] = useState('Home')
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleLinkClick = (tab: Tab) => {
    console.log(location.pathname)
    setCurrentTab(tab.path)
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setCurrentTab(location.pathname)
  }, [location.pathname])

  return (
    <>
      <nav className="navbar">
        <div className="hideOnDesktop">
          <img src={BibotLogo} alt={'BiBot Logo'} width={60} />
        </div>
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          <img
            src={BibotLogo}
            alt={'BiBot Logo'}
            width={60}
            className="hideOnMobile"
          />
          {tabs.map((tab, index) => {
            return (
              <a
                href={
                  currentTab === 'Features' ? `/#${currentTab}` : currentTab
                }
                target={
                  currentTab === ROUTES.DEVELOPER_APIS_PAGE
                    ? '_blank'
                    : '_self'
                }
                rel="noopener noreferrer"
                onClick={() => {
                  handleLinkClick(tab)
                }}
                style={{
                  borderBottom:
                    tab.path === currentTab ? '3px solid #1f4760' : '',
                  cursor: 'pointer',
                  margin: 20,
                }}
                key={index}
              >
                {tab.title}{' '}
                {tab.title === 'Developer' ? (
                  <IconArrowUpRight size={15} />
                ) : (
                  ''
                )}
              </a>
            )
          })}

          <div className="hideOnDesktop">
            <a
              href={ROUTES.ADMIN_PORTAL}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="outline" color="#1f4760">
                Get Started
              </Button>
            </a>
          </div>
        </div>

        <div
          className="hideOnMobile"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <a
            href={ROUTES.ADMIN_PORTAL}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outline" color="#1f4760">
              Get Started
            </Button>
          </a>
        </div>

        <div className="nav-icon" onClick={handleToggle}>
          {isOpen ? <IconX /> : <IconMenu2 />}
        </div>
      </nav>
    </>
  )
}
