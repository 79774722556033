import { appStateEnums } from 'config'
import type { AppStates, AppAction } from 'types'

export const appReducer = (state: AppStates, action: AppAction): AppStates => {
	switch (action.type) {
	case appStateEnums.INIT_STATES:
		return {
			...state,
			theme: action.theme,
			hideImportantMessages: action.hideImportantMessages,
			collapsed: action.collapsed
		}
	case appStateEnums.HIDE_IMPORTANT_NOTIFICATIONS:
		return {
			...state,
			hideImportantMessages: action.hideImportantMessages
		}
	case appStateEnums.COLLAPSE:
		return {
			...state,
			collapsed: action.collapsed
		}
	case appStateEnums.THEME:
		return {
			...state,
			theme: action.theme
		}
	case appStateEnums.LP_DATA:
		return {
			...state,
			lpData: action.lpData
		}
	default:
		return state
	}
}
