const env = process.env.REACT_APP_ENV

const isProd = env === 'prod'

export const APP_DATA = {
  APP_NAME: isProd ? 'Bibot' : 'Bibot-Dev'
}

const baseUrls = {
  core: isProd ? 'https://api.bibot.app/v0/' : 'https://api.dev.bibot.thespuka.com/v0/',
  inference: isProd ? 'https://inference.bibot.app/v0' : 'https://inference.dev.bibot.thespuka.com/v0',
  lp: isProd ? 'https://lp.bibot.app/v0' : 'https://lp.dev.bibot.thespuka.com/v0'
}

export const endpoints = {
	get core () {
		return baseUrls.core
	},
  get inference () {
		return baseUrls.inference
	},
  get lp () {
		return baseUrls.lp
	}
} as const

export enum domain {
  app = '/app',
  core = '/core',
  bibot = '/bibot',
  inference = '/inference',
  lp = '/lp'
}


export enum resources {
  contact = '/contact',
  form = '/form',
  ampConfig = '/amp-config',
  usr = '/usr',
  register = '/register',
  verifyEmail = '/verify-email',
  resetPwd = '/reset-pwd',
  verify = '/verify',
  chatBubble = '/chat-bubble-config',
  predefined = '/predefined',
  predefinedQ = '/predefined-q',
  updatePredefinedQ = '/update-predefined-q',
  deletePredefinedQ = '/delete-predefined-q',
  updateKB = '/update-kb',
  deleteKB = '/delete-kb',
  embed = '/embed',
  transcribe = '/transcribe',
  checkout = '/checkout',
  update = '/update',
  myPlan = '/my-plan',
  plans = '/plans',
  list = '/list-kb',
  rawKb= '/raw-kb',
  subscription = '/subscription',
  chat_history = '/list-chat-history',
  chat_sessions = '/list-chat-sessions',
  listStatistics = '/dashboard-statistics',
  data = '/data',
  scrape='/scrape',
  createUser = '/create-user',
  listUsers = '/list-users',
  deactivateUsers = '/deactivate-users',
  deleteSelectedUsers = '/delete-selected-users',
  updateUserAttributes = '/update-user',
  activateUsers = '/activate-users',
  lm = 'lm',
  secrets = '/rsa',
  jira = '/jira',
  whatsapp = '/q-whatsapp',
  salesforce = '/salesforce',
  slack = '/slack',
  qSlacker = '/q-slacker',
  qTeams = '/q-teams',
  chatBubbleBanner= '/chat-bubble-banner',
  paymentInfo = '/my-payment-info',
  unanswered_q = '/unanswered-q',
  updateCard= '/update-payment-method',
  cancelSubscription = '/cancel-subscription',
  defaultCard = '/set-default-payment-method',
  createPaymentMethod = '/create-payment-method',
  detachCard = '/detach-payment-method'
}