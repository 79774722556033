
const primary_color = '#1f4760'
const secondary_color = '#deebf2'

const pre_secondary = '#e3e3ea'
const pre_primary = '#5E599A'

export{
    primary_color,
    secondary_color,
    pre_secondary,
    pre_primary
}