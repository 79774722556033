import React from 'react'
import { Button, Result, Layout } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config/constants'

const { Content } = Layout

const ErrorPage: React.FC = () => {
	const navigate = useNavigate()

	return (
		<Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
			<Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Result
					status="error"
					title="Something went wrong"
					subTitle="Sorry, something went wrong. Please try again or contact support."
					extra={[
						<Button
							type="primary"
							key="back"
							onClick={() => { navigate(ROUTES.HOME) }}
							style={{
								fontFamily: 'Arial, sans-serif',
								fontSize: '14px',
								borderRadius: '10px',
								border: '2px solid #d67632',
								outline: 'none',
								cursor: 'pointer',
								transition: 'background-color 0.3s, color 0.3s',
								backgroundColor: '#5f2d78',
								color: 'white'
							}}
						>
              Go Back Home
						</Button>
					]}
				/>
			</Content>
		</Layout>
	)
}

export default ErrorPage
