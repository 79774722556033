import { Paper, Title, Card, Text, Button, Badge, Alert } from '@mantine/core'
import { IconMessageChatbot } from '@tabler/icons-react'
import { lpData } from 'data/lp_data'
import React from 'react'
import { primary_color, secondary_color } from 'styles/colors'
import Navbar from './Navbar'
import { ROUTES } from 'config'
import Footer from './footer'
import SubscriptionComparisonTable from './SubComparedPage'

export default function Pricing() {
  function splitText(text: string): [string, string] {
    const parts = text.split('/month')
    return [parts[0], parts[1]]
  }

  return (
    <>
      <Navbar />
      <Paper id="Pricing" bg={'#fff'} className="pricingParent">
        <div style={{ textAlign: 'center', marginBlock: 50 }}>
          <Title
            style={{
              color: '#000',
              fontSize: 50,
              marginBottom: 20,
              textAlign: 'center',
            }}
          >
            Goal-oriented pricing <br /> that scales with you
          </Title>
          <Text>
            Try Trial plan features for 14 days · No credit card required · No
            coding needed
          </Text>

          <Button
            onClick={() => window.open(ROUTES.ADMIN_PORTAL, '_blank')} 
            variant="filled"
            color={primary_color}
            mt={10}
            style={{ minWidth: '14em' }}
          >
            Start your free trial
          </Button>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
          }}
        ></div>

        <div className="pricingCardParent">
          {lpData?.pricing.plans.map((data) => {
            const [part1, part2] = splitText(data.price)
            return (
              <>
                <Card
                  mb={100}
                  className="pricingCard"
                  m={10}
                  withBorder
                  style={{
                    border: data.popular ? `3px solid ${secondary_color}` : '',
                  }}
                >
                  <Card.Section
                    key={data.key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      backgroundColor: data.popular ? secondary_color : '',
                      textAlign: 'center',
                      padding: 20,
                      minHeight: '45%',
                      borderBottom: `1px solid ${secondary_color}`,
                    }}
                  >
                    <div>
                      {data.popular ? (
                        <Badge mb={20} color="#000">
                          Most popular
                        </Badge>
                      ) : (
                        <Badge color="white" mb={20}>
                          {' '}
                        </Badge>
                      )}
                      <Title order={3} style={{ fontSize: 18 }}>
                        {data.name}
                      </Title>
                      {/* {data.popular ? <Text>Basic in a growing company</Text>: `${data.name} in a growing company`} */}
                    </div>
                    <div>
                      <IconMessageChatbot size={60} />
                    </div>
                    <div>
                      <Text style={{ padding: 10 }}>
                        <span style={{ fontSize: 40, fontWeight: 'bold' }}>
                          {part1}
                        </span>
                        /month
                      </Text>
                      <Text style={{ padding: 10, fontSize: 14 }}>
                        {part2}{' '}
                        {data.name !== 'Trial' && (
                          <span>/month billed annually </span>
                        )}
                      </Text>

                      <Button
                        onClick={() => window.open(ROUTES.ADMIN_PORTAL, '_blank')} 
                        variant="default"
                        color={primary_color}
                        mt={10}
                        style={{ minWidth: '14em' }}
                      >
                        Start free trial
                      </Button>
                    </div>
                  </Card.Section>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: 20,
                    }}
                  >
                    <ul
                      style={{ textAlign: 'left', padding: 2, fontSize: '8' }}
                    >
                      {data.features.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                </Card>
              </>
            )
          })}
        </div>
        <Alert
          c={secondary_color}
          p={10}
          m={10}
          style={{ textAlign: 'center' }}
        >
          For custom pricing{' '}
          <a
            href="mailto:bibot@thecloudsecllc.com"
            style={{ color: primary_color }}
          >
            contact us
          </a>
        </Alert>
      </Paper>
      <SubscriptionComparisonTable />
      <Footer />
    </>
  )
}
