import { appStateEnums } from 'config'
import React, {
  type ReactNode,
  createContext,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { appReducer } from 'reducers/appReducer'
import { getLpData } from 'services'
import type { AppContextProps, AppStates, lpDataProps } from 'types'

interface AppProps {
  children: ReactNode;
}

const dummyDispatch = () => {} // A no-op function.

const defaultContextValue: AppContextProps = {
  Appstate: {
    theme: 'dark',
  },
  dispatch: dummyDispatch,
}

const AppContext = createContext<AppContextProps>(defaultContextValue)

const AppProvider: React.FC<AppProps> = ({ children }) => {
  const APP_STATE_KEY = 'app_states'
  const initialState: AppStates = useMemo(() => ({
    theme: 'dark',
  }), [])
  const [Appstate, dispatch] = useReducer(appReducer, initialState)

  useEffect(() => {
    // Pool the initial states from local storage
    const storedStates = localStorage.getItem(APP_STATE_KEY)

    if (storedStates) {
      try {
        const parsedStates = JSON.parse(storedStates)
        const updatedStates = {
          ...initialState,
          ...parsedStates,
        }
        dispatch({ type: appStateEnums.INIT_STATES, ...updatedStates })
      } catch (error) {
        console.error('Failed to parse app states from local storage:', error)
      }
    }
  }, [initialState])

  const handleGetLpData = useCallback(async () => {
    const serviceLpData: lpDataProps = await getLpData()
    if (serviceLpData) {
      dispatch({
        type: appStateEnums.LP_DATA,
        lpData: serviceLpData,
        theme: Appstate.theme,
        hideImportantMessages: false,
        collapsed: false,
        chatIsOpen: false,
      })
    }
  }, [Appstate.theme])

  useEffect(() => {
    void handleGetLpData()
  }, [handleGetLpData])

  useEffect(() => {
    localStorage.setItem(APP_STATE_KEY, JSON.stringify(Appstate))
  }, [Appstate])

  return (
    <AppContext.Provider value={{ Appstate, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
export { AppContext, AppProvider }
