import ReactDOM from 'react-dom/client'
import { AppProvider } from 'context'
import './styles/index.css'
import App from './App'
import { reportWebVitals } from './reportWebVitals'

async function configureAndStartApp(): Promise<void> {
  const root = ReactDOM.createRoot(document.getElementById('root')!)
  root.render(
    <AppProvider>
      <App />
    </AppProvider>
  )
}

configureAndStartApp().catch(console.error)

reportWebVitals()
