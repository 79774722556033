import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ROUTES } from 'config'
import ErrorPage from 'components/utils/ErrorPage'
import LandingPage from 'components/views/landing-page'
import Pricing from 'components/pages/pricing'
const AppRoutes: React.FC = () => (
	<Routes>
		<Route path={ROUTES.HOME} element={<LandingPage />} />
		<Route path={ROUTES.PRICING} element={<Pricing />} />
		<Route path={ROUTES.DEVELOPER} element={<p>Developer</p>} />
		<Route path={ROUTES.ERROR} element={<ErrorPage />} />
	</Routes>
)

export { AppRoutes }
