// import { domain, resources } from 'config'
// import { createLpAxiosInstance } from 'hooks'
import { lpDataProps } from 'types'
import { lpData } from '../data/lp_data'

// const lpAxiosInstance = createLpAxiosInstance()

async function getLpData(): Promise<lpDataProps> {
  try {
    // const path = `${domain.lp}${resources.data}`
    // const response = await lpAxiosInstance.get<lpDataProps>(path)
    // return response.data
    return lpData

  } catch (error) {
    console.error('Error fetching subscription:', error)
    return lpData
  }
}

export {
  getLpData,
}
