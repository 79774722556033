import { domain, resources } from 'config'
import { createCoreAxiosInstance } from 'hooks'

const coreAxiosInstance = createCoreAxiosInstance()


async function get_ChatBubble_Config() {
  try {
    const path = `${domain.app}${resources.chatBubble}`
    const response = await coreAxiosInstance.get(path)
    return response.data
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
  }
}

async function chat_Bubble_Banner(check: boolean) {
  const data = { powered_by_bibot: check }
  try {
    const path = `${domain.app}${resources.chatBubbleBanner}`
    const response = await coreAxiosInstance.post(
      path,
      data
    )
    return response.data
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
  }
}

export { get_ChatBubble_Config, chat_Bubble_Banner }
